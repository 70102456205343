<template>
  <v-main>
    <v-app-bar class="ukpl-main-navigation" v-if="currentUser" dense dark elevate-on-scroll>
      <div class="ukpl-main-navigation-container">
        <div class="navigation-left-wrap">
          <a href="https://www.ukpl.co.uk">
            <v-img src="@/assets/ukpl-logo-new.png" alt="UKPL logo" contain class="ukpl-nav-logo" />
          </a>
        </div>

        <v-spacer></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down">
          <!-- <v-btn color="#0072ff" depressed to="/home"
            ><v-icon class="mr-2">fa-home</v-icon>Home</v-btn
          > -->
          <v-btn v-if="isAdmin" color="#0072ff" depressed to="/admin">admin</v-btn>
          <v-btn depressed to="/team"><v-icon class="mr-2">fa-users</v-icon>Teams</v-btn>
          <v-btn v-if="currentCaptain" depressed to="/roster"><v-icon class="mr-2">fa-list</v-icon>Roster</v-btn>
          <v-btn depressed to="/profile"><v-icon class="mr-2">fa-user</v-icon>Profile</v-btn>
          <v-btn depressed to="/disclaimer"><v-icon class="mr-2">fa-file-signature</v-icon>Disclaimer</v-btn>
          <v-btn depressed to="/idcard"><v-icon class="mr-2">far fa-id-card</v-icon>ID Card</v-btn>
          <v-btn depressed @click.native="logOut"><v-icon class="mr-2">fa-sign-out-alt</v-icon>LogOut</v-btn>
        </v-toolbar-items>
      </div>

      <v-toolbar-items class="hidden-md-and-up">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      </v-toolbar-items>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group>
          <v-list-item v-if="isAdmin" key="admin" to="/admin">
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item>
          <v-list-item key="team" to="/team">
            <v-list-item-icon><v-icon>fa-users</v-icon></v-list-item-icon>
            <v-list-item-title>Teams</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="currentCaptain" key="roster" to="/roster">
            <v-list-item-icon><v-icon>fa-list</v-icon></v-list-item-icon>
            <v-list-item-title>Roster</v-list-item-title>
          </v-list-item>
          <v-list-item key="profile" to="/profile">
            <v-list-item-icon><v-icon>fa-user</v-icon></v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item key="disclaimer" to="/disclaimer">
            <v-list-item-icon><v-icon>fa-file-signature</v-icon></v-list-item-icon>
            <v-list-item-title>Disclaimer</v-list-item-title>
          </v-list-item>
          <v-list-item key="id-card" to="/idcard">
            <v-list-item-icon><v-icon>far fa-id-card</v-icon></v-list-item-icon>
            <v-list-item-title>ID Card</v-list-item-title>
          </v-list-item>
          <v-list-item @click.native="logOut" key="icon">
            <v-list-item-icon><v-icon>fa-sign-out-alt</v-icon></v-list-item-icon>
            <v-list-item-title>LogOut</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Main Form -->
    <v-sheet class="ukpl-blue-card-container" height="100%">
      <v-container fluid height="100%">
        <v-form ref="form" class="mx-2" lazy-validation>
          <v-row justify="center" align="center" class="row">
            <v-col cols="12">
              <h1 class="h1-heading">
                Please select the division and team you will be playing in for the {{ new Date().getFullYear() }}
                season.
              </h1>
              <br />

              <p class="text-left">
                NOTE: If you can't locate your team in the division list,
                please get your team captain to contact us either on fb
                messenger or
                <a style="color: #c1c1c1" href="mailto:admin@ukpl.co.uk">admin@ukpl.co.uk</a>
                to register your team.
              </p>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="row">
            <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
              <v-select ref="divselect" v-model="selectdivteam" :items="divisionsteams" :item-text="'combinedName'"
                :item-value="'combinedId'" label="Team/Division" dense :rules="rules" return-object
                v-on:change="updateDivTeam"></v-select>
            </v-col>
          </v-row>

          <v-row justify="start" class="row">
            <v-col cols="12" sm="12" md="10" xs="12">
              <h1 class="h1-heading">
                By signing the following disclaimer you are accepting these
                conditions for the year of {{ new Date().getFullYear() }}
              </h1>
              <br />
              <p>
                Please read the following statement as by signing this form you
                agree to listen to the safety brief and abide by the rules set
                out and all safety procedures
              </p>
              <p><strong>Basic rules:</strong></p>
              <ul>
                <li>
                  Paintball is a physically intensive sport, you may well walk
                  away with some bumps and bruises from your game. If shot at
                  close range it may hurt more so please be aware of the
                  distance between you and the opposition.
                </li>
                <li>
                  Whilst participating in the CPPS/OKPB organised events you
                  agree to adhere to all the relevant rules, and conduct
                  yourself in an appropriate manner. All your kit is assumed fit
                  for purpose in the sport of paintball and meets all current
                  safety requirements and legislation.
                </li>
                <li>
                  Whilst we at OKPB Ltd have taken great lengths to ensure the
                  safety of our customers, we cannot accept any liability for
                  accident or injury whilst on our premises. We carry all the
                  relevant Public liability insurance but we do not offer any
                  kind of personal insurance, you participate in our activities
                  at your own risk.
                </li>
                <li>
                  As we operate a self-fill air supply, your air systems must be in test (date) and conform to UK
                  regulations. It is your responsibility to ensure your equipment is fit for purpose and meets industry
                  standards.
                </li>
                <li>
                  Please enjoy your season and if there is anything you need or
                  you are unsure of ask a member of staff.
                </li>
                <br />
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="userDetails.emergencycontactname" :rules="mandatoryRules"
                label="Emergency Contact Name" dense>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="userDetails.emergencycontactnumber" :rules="mandatoryRules"
                label="Emergency Contact Number" dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="row">
            <v-col cols="12" sm="12" md="12" xs="12" class="text-center">
              <v-checkbox v-model="disclaimer" :rules="mandatoryRules"
                label="I hereby release, remise and forever discharge from any claims and liabilities whatsoever without limitation that I might have against OKPB Ltd, the owners of the property on which the events are being played and any other player who might injure me howsoever arising, and I make this release on behalf of myself, my heirs, executors and administrators.">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row justify="center" align="center" class="row">
            <v-col cols="6" sm="6" md="6" xs="6 " class="text-center">
              <v-btn class="red-btn-primary" rounded dark @click="save">Next</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-sheet>
    <v-snackbar :timeout="2000" v-model="messageon" color="red accent-4" rounded="pill" elevation="24">
      {{ message }}
    </v-snackbar>
    <v-snackbar :timeout="1000" v-model="savesuccess" color="green accent-4" rounded="pill" elevation="24">
      Details Saved.
    </v-snackbar>
  </v-main>
</template>

<script>
import UserService from "../services/user.service";
import UserDetails from "../model/userdetails";
import DivisionService from "../services/division.service";
import UtilService from "../services/util.service";

export default {
  name: "Disclaimer",
  components: {},
  data: () => ({
    drawer: false,
    messageon: false,
    savesuccess: "",
    savefail: "",
    message: "",
    disclaimer: false,
    userDetails: new UserDetails(),
    mandatoryRules: [],
    currentCaptain: false,
    divisionsteams: [],
    selectdiv: "1",
    selectteam: "",
    selectdivteam: "",
    rules: [],
    currentyear: 0,
  }),
  mounted() {
    this.mandatoryRules = [(v) => !!v || "Field is required"];
    this.rules = [(v) => {
      var result;
      if (/[0-9]+\|[0-9]+/.test(v.combinedId) || /[0-9]+\|[0-9]+/.test(v)) {
        result = null;
      } else {
        result = "please select a value.";
      }
      return result;
    }];
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes("ROLE_ADMIN");
      }
      return false;
    },
  },
  methods: {
    isCaptain() {
      UserService.isTeamCaptain().then(
        (response) => {
          if (response.data) {
            this.currentCaptain = true;
          } else {
            this.currentCaptain = false;
          }
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    logOut() {
      console.log("logging out");
      this.$store.dispatch("auth/logout");
      this.$router.push("/home");
    },
    save() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      if (!this.$refs.divselect.validate()) {
        return false;
      }
      console.log("form verified, saving.");
      this.userDetails.teamid = this.selectteam;
      this.userDetails.divid = this.selectdiv;

      UserService.signDisclaimer(this.userDetails).then(
        (response) => {
          this.message = response.message;
          this.registersuccess = true;
          this.savesuccess = true;
        },
        (error) => {
          this.failmessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.savefail = true;
        }
      );
    },
    initializeDivTeam() {
      DivisionService.getDivisionsTeams().then(
        (response) => {
          this.divisionsteams = response.data;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    updateDivTeam(selObj) {
      this.selectdiv = selObj.divId;
      this.selectteam = selObj.teamId;
    },
    initCurrentYear() {
      UtilService.getCurrentYear().then(
        (response) => {
          this.currentyear = response.data.year;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
  created() {
    this.initCurrentYear();
    this.isCaptain();
    this.initializeDivTeam();
    UserService.getUserDetails().then(
      (response) => {
        this.userDetails.username = response.data.username;
        this.userDetails.email = response.data.email;
        this.userDetails.password = response.data.password;
        this.userDetails.firstname = response.data.firstname;
        this.userDetails.lastname = response.data.lastname;
        this.userDetails.gender = response.data.gender;
        this.userDetails.dateOfBirth = response.data.dateOfBirth;
        this.userDetails.contactNumber = response.data.contactNumber;
        this.userDetails.addressline1 = response.data.addressline1;
        this.userDetails.addressline2 = response.data.addressline2;
        this.userDetails.town = response.data.town;
        this.userDetails.county = response.data.county;
        this.userDetails.postcode = response.data.postcode;
        this.userDetails.file = response.data.file;
        this.userDetails.ukpsf = response.data.ukpsf;
        this.userDetails.teamcaptain = response.data.teamcaptain;
        this.userDetails.disclaimer = response.data.disclaimer;
        this.userDetails.disclaimerdate = response.data.disclaimerDate;
        this.userDetails.emergencycontactname =
          response.data.emergencyContactName;
        this.userDetails.emergencycontactnumber =
          response.data.emergencyContactNumber;
        this.disclaimer = response.data.disclaimer;

        var thisYearDivision = null;
        for (thisYearDivision in response.data.userHistory) {
          if (
            this.currentyear ===
            parseInt(response.data.userHistory[thisYearDivision].year)
          ) {
            this.thisYearDivision =
              response.data.userHistory[thisYearDivision].division.id;
            this.selectdiv = this.thisYearDivision;
            break;
          }
        }

        var currentTeam = null;

        for (currentTeam in response.data.userHistory) {
          if (
            this.currentyear ===
            parseInt(response.data.userHistory[currentTeam].year)
          ) {
            this.selectteam = response.data.userHistory[currentTeam].team.id;
            break;
          }
        }
        if (this.selectdiv != null && this.selectteam != null) {
          this.selectdivteam = this.selectdiv + "|" + this.selectteam;
        }
      },
      (error) => {
        this.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  watch: {
    disclaimer(val) {
      this.userDetails.disclaimer = val;
      if (val == undefined || val == false) {
        this.userDetails.disclaimerdate = null;
      } else {
        this.userDetails.disclaimerdate = new Date();
      }
    },
    savesuccess: function () {
      if (this.savesuccess === false) {
        this.$router.push("/idcard");
      }
    },
  },
};
</script>
